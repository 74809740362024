import StyledButton from '@components/Shared/StyledButton';
import { FormikCheckbox } from '@shared//form-elements/FormikCheckbox';
import { FormikInput } from '@shared//form-elements/FormikInput';
import FormHeader from '@shared//FormHeader';
import FormLayout from '@shared//FormLayout';
import FormLayoutColOne from '@shared//FormLayoutColOne';
import FormLayoutColTwo from '@shared//FormLayoutColTwo';
import { UserProps } from '@utilities/interfaces/user';
import { RegistrationOne } from '@utilities/validation';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SelectedPackage from './SelectedPackage';

interface RegisterOneProps {
  callback?: (id: string) => void;
  initialValues: UserProps;
  onSubmit: (values: UserProps) => void;
}

const RegisterOne: React.FC<RegisterOneProps> = ({ onSubmit, initialValues, callback }) => {
  const { packageid } = useParams<{ packageid: string }>();
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (callback && packageid) {
      setShow(true);
      callback(packageid);
    }
  }, [packageid, callback]);

  return (
    initialValues &&
    show && (
      <FormLayout mobileReverse>
        <FormLayoutColOne>
          <FormHeader title="Vul je gegevens in" indicator="Stap 1/2" />
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={RegistrationOne}
            onSubmit={onSubmit}
          >
            <Form>
              <FormikInput label="E-mailadres" name="applicant.email" type="email" formType="secondary" />
              <FormikInput label="+31 Mobiel nummer" name="applicant.mobilePhone" type="tel" formType="secondary" />
              <FormikCheckbox
                label="Ik heb de <b><a href='https://brinks.nl/media/1220/brinks-a4-algemene-voorwaarden-bav010620-nl-def.pdf' target='_blank'>Algemene Voorwaarden</a></b>, de <b><a href='/' target='_blank'>Betalingsvoorwaarden</a></b> en het <b><a href='https://brinks.nl/privacystatement-brinks-solutions-nederland-bv/' target='_blank'>Privacybeleid</a></b> van Brink's gelezen en ga hiermee akkoord."
                name="applicant.acceptTerms"
              />
              <StyledButton tag="button" text="Volgende" type="submit" />
            </Form>
          </Formik>
        </FormLayoutColOne>
        <FormLayoutColTwo selectedPackage>
          {initialValues?.productType && <SelectedPackage productid={initialValues.productType} />}
        </FormLayoutColTwo>
      </FormLayout>
    )
  );
};

export default RegisterOne;
